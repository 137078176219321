
import Vue from "vue";
import Component from "vue-class-component";
import { DxButton } from "devextreme-vue/button";
import { Routes } from "@/router/routes";
import { userModule } from "@/store/modules/user";
import { UserActions } from "@/store/modules/user/actions";
import UserEditor from "@/components/UserEditor";
import { UserGetters } from "@/store/modules/user/getters";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import { User } from "@/models/User";
import { UserMutations } from "../../store/modules/user/mutations";
import { RoleActions } from "@/store/modules/role/actions";
import { roleModule } from "@/store/modules/role";
import Errors from "./BackendResponseErrors.json";
import notify from "devextreme/ui/notify";
import DynamicTitle from "@/components/DynamicTitle";
import FixedPanel from "@/components/FixedPanel";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import { ClickEvent } from "devextreme/ui/button";
import { ValidationResult } from "devextreme/ui/validation_group";

@Component({
    components: { DxButton, UserEditor, DynamicTitle, FixedPanel },
    computed: {
        ...userModule.mapGetters({
            isEdited: UserGetters.IsEdited,
            user: UserGetters.User,
            isLoading: UserGetters.Loading
        })
    },
    methods: {
        ...userModule.mapActions({
            loadUserDetailsById: UserActions.LoadUserDetailsById,
            commitUserUpdate: UserActions.CommitUserUpdate
        }),
        ...userModule.mapMutations({
            setUser: UserMutations.SetUser,
            setErrors: UserMutations.SetErrors
        }),
        ...roleModule.mapActions({
            loadRoles: RoleActions.LoadRoles
        }),
        ...userModule.mapGetters({
            getErrors: UserGetters.Errors
        })
    }
})
export default class EditUser extends Vue {
    private readonly loadUserDetailsById!: (userId: number) => Promise<void>;
    private readonly commitUserUpdate!: () => Promise<void>;
    private readonly setUser!: (user: Partial<User> | null) => void;
    private readonly loadRoles!: () => Promise<void>;
    private readonly getErrors!: () => string[] | null;
    private readonly setErrors!: (payload: string[] | null) => void;

    protected readonly isLoading!: boolean;
    protected readonly user!: Partial<User> | null;
    private readonly isEdited!: boolean;
    private leaveConfirmationEnabled = true;
    protected readonly roles!: string[];

    protected readonly titleElements: TitleElement[] = [
        { name: "Overview", selected: false, link: Routes.Users },
        { name: "Edit User", selected: true }
    ];

    created(): void {
        if (!this.roles) {
            this.loadRoles();
        }

        if (this.userId) {
            this.setUser(null);
            this.loadUserDetailsById(this.userId);
        }
        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    saveUser(e: ClickEvent): void {
        const result = e.validationGroup.validate() as ValidationResult;
        if (result.isValid) {
            this.commitUserUpdate().then(() => {
                const errors = this.getErrors();
                this.setErrors(null);

                if (errors) {
                    const validationErrors = errors.filter((e) =>
                        e.includes(Errors.ValidationProblem.Error)
                    );
                    let notifyError: string;
                    if (
                        errors.find((e) =>
                            e.includes(Errors.DuplicateEmail.Error)
                        )
                    ) {
                        notifyError = Errors.DuplicateEmail.Message;
                    } else if (validationErrors?.length) {
                        notifyError = validationErrors
                            .map((error) => {
                                return error.substr(
                                    error.search("message:") + 8
                                );
                            })
                            .join(" ");
                    } else {
                        notifyError = Errors.Default.Message;
                    }
                    notify(notifyError, "error", 10000);
                } else {
                    this.leaveConfirmationEnabled = false;
                    this.$router.push(Routes.Users);
                }
            });
        }
    }

    get userId(): number | null {
        const userId = Number(this.$route.params.id);
        return !isNaN(userId) ? userId : null;
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
