
import Vue from "vue";
import Component from "vue-class-component";
import { DxButton } from "devextreme-vue/button";
import { Routes } from "@/router/routes";
import { userModule } from "@/store/modules/user";
import UserEditor from "@/components/UserEditor";
import { UserActions } from "@/store/modules/user/actions";
import { UserGetters } from "@/store/modules/user/getters";
import { UserMutations } from "@/store/modules/user/mutations";
import { leaveConfirmation } from "@/services/leaveConfirmation";
import { TitleElement } from "@/components/DynamicTitle/TitleElement";
import Errors from "./BackendResponseErrors.json";
import notify from "devextreme/ui/notify";
import DynamicTitle from "@/components/DynamicTitle";
import FixedPanel from "@/components/FixedPanel";
import { roleModule } from "@/store/modules/role";
import { RoleActions } from "@/store/modules/role/actions";
import { ClickEvent } from "devextreme/ui/button";
import { ValidationResult } from "devextreme/ui/validation_group";

@Component({
    components: { DxButton, UserEditor, DynamicTitle, FixedPanel },
    computed: {
        ...userModule.mapGetters({
            isEdited: UserGetters.IsEdited
        })
    },
    methods: {
        ...userModule.mapActions({
            addNewUser: UserActions.AddNewUser,
            commitCreateUser: UserActions.CreateUser
        }),
        ...userModule.mapGetters({
            getErrors: UserGetters.Errors
        }),
        ...userModule.mapMutations({
            setErrors: UserMutations.SetErrors
        }),
        ...roleModule.mapActions({
            loadRoles: RoleActions.LoadRoles
        })
    }
})
export default class CreateUser extends Vue {
    private readonly addNewUser!: () => void;
    private readonly commitCreateUser!: () => Promise<void>;
    private readonly getErrors!: () => string[] | null;
    private readonly setErrors!: (payload: string[] | null) => void;
    private readonly loadRoles!: () => Promise<void>;

    protected readonly roles!: string[];

    private readonly isEdited!: boolean;

    private leaveConfirmationEnabled = true;

    protected readonly titleElements: TitleElement[] = [
        { name: "Overview", selected: false, link: Routes.Users },
        { name: "Create New User", selected: true }
    ];

    created(): void {
        if (!this.roles) {
            this.loadRoles();
        }

        this.addNewUser();
        window.onbeforeunload = () => this.isEdited;
    }

    beforeDestroy(): void {
        window.onbeforeunload = null;
    }

    backButtonClick(): void {
        this.$router.push(Routes.Users);
    }

    async createUser(e: ClickEvent): Promise<void> {
        const result = e.validationGroup.validate() as ValidationResult;
        if (result.isValid) {
            await this.commitCreateUser();
            const errors = this.getErrors();
            this.setErrors(null);

            if (errors) {
                const validationErrors = errors.filter((e) =>
                    e.includes(Errors.ValidationProblem.Error)
                );
                let notifyError: string;
                if (
                    errors.find((e) => e.includes(Errors.DuplicateEmail.Error))
                ) {
                    notifyError = Errors.DuplicateEmail.Message;
                } else if (validationErrors?.length) {
                    notifyError = validationErrors
                        .map((error) => {
                            return error.substr(error.search("message:") + 8);
                        })
                        .join(" ");
                } else {
                    notifyError = Errors.Default.Message;
                }
                notify(notifyError, "error", 10000);
            } else {
                this.leaveConfirmationEnabled = false;
                this.$router.push(Routes.Users);
            }
        }
    }

    beforeRouteLeave(
        _to: never,
        _from: never,
        next: () => void
    ): boolean | void {
        leaveConfirmation(this.isEdited && this.leaveConfirmationEnabled, next);
    }
}
