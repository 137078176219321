
import Vue from "vue";
import Component from "vue-class-component";
import {
    DxDataGrid,
    DxEditing,
    DxPaging,
    DxPager,
    DxColumn,
    DxButton,
    DxFilterRow,
    DxLookup,
    DxHeaderFilter
} from "devextreme-vue/data-grid";
import { userModule } from "@/store/modules/user";
import { roleModule } from "@/store/modules/role";
import { UserGetters } from "@/store/modules/user/getters";
import { User } from "@/models/User";
import { ColumnButtonClickEvent } from "devextreme/ui/data_grid";
import { Routes } from "@/router/routes";
import { UserActions } from "@/store/modules/user/actions";
import { RoleGetters } from "../../store/modules/role/getters";
import { confirm, alert } from "devextreme/ui/dialog";

@Component({
    components: {
        DxDataGrid,
        DxEditing,
        DxPaging,
        DxPager,
        DxColumn,
        DxButton,
        DxFilterRow,
        DxLookup,
        DxHeaderFilter
    },
    computed: {
        ...userModule.mapGetters({
            users: UserGetters.Users
        }),
        ...roleModule.mapGetters({
            roles: RoleGetters.Roles
        })
    },
    methods: {
        ...userModule.mapActions({
            deleteUserById: UserActions.DeleteUserById,
            getTwoFactorRecoveryCode: UserActions.GetTwoFactorRecoveryCode
        })
    }
})
export default class UserList extends Vue {
    private readonly deleteUserById!: (userId: number) => Promise<void>;
    private readonly getTwoFactorRecoveryCode!: (
        userId: number
    ) => Promise<string>;

    protected readonly users!: User[];
    protected readonly roles!: string[];

    editUser(e: ColumnButtonClickEvent<User>): void {
        if (e.row?.key) {
            this.$router.push(Routes.EditUser + e.row.key);
        }
    }

    async deleteUser(e: ColumnButtonClickEvent<User>): Promise<void> {
        const id = e.row?.data.id;
        if (id) {
            const confirmResult = await confirm(
                `Are you sure you want to delete ${e.row?.data.email}?`,
                "Confirm delete"
            );
            if (confirmResult) {
                await this.deleteUserById(id);
            }
        }
    }

    async generateCode(e: ColumnButtonClickEvent<User>): Promise<void> {
        const id = e.row?.data.id;
        if (id) {
            await alert(
                `Two factor emergency code for ${
                    e.row?.data.email
                }: ${await this.getTwoFactorRecoveryCode(id)}`,
                "Emergency Code"
            );
        }
    }

    has2FADisabled(e: ColumnButtonClickEvent<User>): boolean {
        return !e.row?.data.twoFactorEnabled ?? true;
    }

    preprocessUserData(user: User): unknown {
        const mapAndJoin = (array: string[] | undefined, separator = ", ") => {
            if (!array || array.length === 0) {
                return "";
            }
            return array.map((item) => item || "-").join(separator);
        };

        return {
            ...user,
            departments: mapAndJoin(user.departments),
            positions: mapAndJoin(user.positions),
            markets: mapAndJoin(user.markets),
            marketTypes: mapAndJoin(user.marketTypes),
            marketRegions: mapAndJoin(user.marketRegions)
        };
    }
    get preprocessedUsers(): unknown[] {
        return this.users.map(this.preprocessUserData);
    }
}
