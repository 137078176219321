
import Vue from "vue";
import Component from "vue-class-component";
import { User } from "@/models/User";
import { UserMarket } from "@/models/UserMarket";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxTagBox } from "devextreme-vue/tag-box";
import { UserMutations } from "@/store/modules/user/mutations";
import { marketModule } from "@/store/modules/market";
import { MarketGetters } from "@/store/modules/market/getters";
import { Market } from "@/models/market/Market";
import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";
import { DxAccordion } from "devextreme-vue/accordion";
import { UpdateEntity } from "@/models/UpdateEntity";
import { DxValidator, DxRequiredRule } from "devextreme-vue/validator";

@Component({
    components: {
        DxTextBox,
        DxTagBox,
        DxTabPanel,
        DxItem,
        DxAccordion,
        DxValidator,
        DxRequiredRule
    },
    computed: {
        ...userModule.mapGetters({
            user: UserGetters.User
        }),
        ...marketModule.mapGetters({
            markets: MarketGetters.Markets
        })
    },
    methods: {
        ...userModule.mapMutations({
            addUserMarkets: UserMutations.AddUserMarkets,
            removeUserMarkets: UserMutations.RemoveUserMarkets,
            updateUserMarket: UserMutations.UpdateUserMarket
        })
    }
})
export default class UserMarketEditor extends Vue {
    private readonly addUserMarkets!: (marketIds: number[]) => void;
    private readonly removeUserMarkets!: (marketIds: number[]) => void;
    private readonly updateUserMarket!: (
        entity: UpdateEntity<UserMarket, number>
    ) => void;

    protected readonly markets!: Market[];
    protected readonly user!: Partial<User> | null;
    protected filterMarket = "";
    protected selectedMarket: Market | null = null;

    get selectedMarketIds(): number[] {
        return this.user?.marketUsers?.map((um) => um.marketId) ?? [];
    }
    set selectedMarketIds(value: number[]) {
        if (this.user) {
            const newMarketIds = value.filter(
                (v) => !this.selectedMarketIds.includes(v)
            );
            if (newMarketIds.length) {
                this.addUserMarkets(newMarketIds);
            }
            const removedMarketIds = this.selectedMarketIds.filter(
                (id) => !value.includes(id)
            );
            if (removedMarketIds.length) {
                this.removeUserMarkets(removedMarketIds);
            }
        }
    }

    get userMarkets(): Market[] {
        const userMarkets: Market[] = [];

        this.selectedMarketIds.forEach((marketId) => {
            const market = this.markets.find((m) => m.marketId === marketId);
            if (
                market &&
                market.name.toLowerCase().includes(this.filterMarket)
            ) {
                userMarkets.push(market);
            }
        });
        return userMarkets;
    }

    get selectedUserMarket(): UserMarket | null {
        return (
            this.user?.marketUsers?.find(
                (mu) => mu.marketId === this.selectedMarket?.marketId
            ) ?? null
        );
    }

    get selectedMarketDepartment(): string {
        return this.selectedUserMarket?.department ?? "";
    }
    set selectedMarketDepartment(value: string) {
        if (this.selectedMarket) {
            this.updateUserMarket({
                key: this.selectedMarket.marketId,
                fields: { department: value }
            });
        }
    }

    get selectedMarketPosition(): string {
        return this.selectedUserMarket?.position ?? "";
    }
    set selectedMarketPosition(value: string) {
        if (this.selectedMarket) {
            this.updateUserMarket({
                key: this.selectedMarket.marketId,
                fields: { position: value }
            });
        }
    }
}
