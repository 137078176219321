
import Vue from "vue";
import Component from "vue-class-component";
import { User } from "@/models/User";
import { userModule } from "@/store/modules/user";
import { UserGetters } from "@/store/modules/user/getters";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxSelectBox } from "devextreme-vue/select-box";
import {
    DxValidator,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmailRule
} from "devextreme-vue/validator";
import { DxTagBox } from "devextreme-vue/tag-box";
import { marketModule } from "@/store/modules/market";
import { MarketActions } from "@/store/modules/market/actions";
import { isUserInRoles } from "@/services/userUtils";
import UserInfoEditor from "@/components/UserInfoEditor";
import UserMarketEditor from "@/components/UserMarketEditor";
import BaseButton from "@/components/BaseButton";

@Component({
    components: {
        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxEmailRule,
        DxTagBox,
        UserInfoEditor,
        UserMarketEditor,
        BaseButton
    },
    computed: {
        ...userModule.mapGetters({
            user: UserGetters.User
        })
    },
    methods: {
        ...marketModule.mapActions({
            loadMarkets: MarketActions.LoadMarkets
        })
    }
})
export default class UserEditor extends Vue {
    private readonly loadMarkets!: () => Promise<void>;
    protected readonly user!: Partial<User> | null;

    protected marketEditorShow = false;

    created(): void {
        this.loadMarkets();
    }

    get isMarketUser(): boolean {
        if (this.user) {
            return isUserInRoles(["MSU", "MU", "MV"], this.user as User);
        }
        return false;
    }
}
