
import Vue from "vue";
import Component from "vue-class-component";
import { User } from "@/models/User";
import { userModule } from "@/store/modules/user";
import { roleModule } from "@/store/modules/role";
import { UserGetters } from "@/store/modules/user/getters";
import { UserMutations } from "@/store/modules/user/mutations";
import { DxTextBox } from "devextreme-vue/text-box";
import { DxSelectBox } from "devextreme-vue/select-box";
import {
    DxValidator,
    DxRequiredRule,
    DxStringLengthRule,
    DxEmailRule
} from "devextreme-vue/validator";
import { RoleGetters } from "@/store/modules/role/getters";
import { canUserManageRole } from "@/services/userUtils";

@Component({
    components: {
        DxTextBox,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
        DxStringLengthRule,
        DxEmailRule
    },
    computed: {
        ...userModule.mapGetters({
            user: UserGetters.User
        }),
        ...roleModule.mapGetters({
            roles: RoleGetters.Roles
        })
    },
    methods: {
        ...userModule.mapMutations({
            updateUser: UserMutations.UpdateUser
        })
    }
})
export default class UserInfoEditor extends Vue {
    private readonly updateUser!: (user: Partial<User>) => void;
    protected readonly user!: Partial<User> | null;

    protected readonly roles!: string[];

    get availableRoles(): string[] {
        return this.roles.filter((r) => canUserManageRole(r));
    }

    get firstName(): string {
        return this.user?.firstName ?? "";
    }
    set firstName(value: string) {
        this.updateUser({ firstName: value });
    }

    get lastName(): string {
        return this.user?.lastName ?? "";
    }
    set lastName(value: string) {
        this.updateUser({ lastName: value });
    }

    get email(): string {
        return this.user?.email ?? "";
    }
    set email(value: string) {
        this.updateUser({ email: value });
    }

    get role(): string {
        return this.user?.role ?? "";
    }
    set role(value: string) {
        this.updateUser({ role: value });
    }
}
