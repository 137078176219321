
import Vue from "vue";
import Component from "vue-class-component";
import UserList from "@/components/UserList";
import { userModule } from "@/store/modules/user";
import { roleModule } from "@/store/modules/role";
import { UserActions } from "@/store/modules/user/actions";
import { RoleActions } from "@/store/modules/role/actions";
import { Routes } from "@/router/routes";
import DynamicTitle from "@/components/DynamicTitle";
import BaseButton from "@/components/BaseButton";

@Component({
    components: { BaseButton, UserList, DynamicTitle },
    methods: {
        ...userModule.mapActions({
            loadUsers: UserActions.LoadUsers,
            exportUsers: UserActions.ExportUsers
        }),
        ...roleModule.mapActions({
            loadRoles: RoleActions.LoadRoles
        })
    }
})
export default class Users extends Vue {
    private readonly loadUsers!: () => Promise<void>;
    private readonly loadRoles!: () => Promise<void>;
    protected readonly exportUsers!: () => Promise<void>;

    created(): void {
        this.loadUsers();
        this.loadRoles();
    }

    addNewUser(): void {
        this.$router.push(Routes.CreateUser);
    }
}
